// Icons

.icons-list {
    border-left: 1px solid $border-color;
    border-top: 1px solid $border-color;
    > div {
        border-bottom: 1px solid $border-color;
        border-right: 1px solid $border-color;
        background: $body-bg;
        display: flex;
        align-items: center;
        padding: 15px 20px;
        font-weight: 400;
        transition: all 0.3s ease-in-out;

        i {
            display: inline-block;
            font-size: 20px;
            text-align: left;
            margin-right: 12px;
            color: $secondary;
            transition: all 0.3s ease-in-out;
        }

        svg {
            width: 20px;
            margin-right: 12px;
            color: $secondary;
            transition: all 0.3s ease-in-out;
        }

        &:hover {
            cursor: text;
            i,
            svg {
                transform: scale(1.3);
                color: $primary;
            }
        }
    }
}
