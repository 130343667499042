.nav {
    &.nav-tabs {
        .nav-item {
            .nav-link {
                border-color: $nav-tabs-link-border-color;
                color: $body-color;
                background-color: $nav-tabs-link-bg;
                cursor: pointer;
                &.active {
                    border-color: $nav-tabs-link-active-border-color;
                    color: $primary;
                    background: $nav-tabs-link-active-bg;
                }
                &.disabled {
                    background-color: transparent;
                    color: $text-muted;
                    border-color: rgba($border-color, 0.7) rgba($border-color, 0.7) transparent;
                }
            }
        }
        &.nav-tabs-vertical {
            border-bottom-color: transparent;
            .nav-item {
                .nav-link {
                    width: 100%;
                    border: 1px solid transparent;
                    border-radius: 0;
                    border-color: $nav-tabs-link-border-color;
                    background-color: $nav-tabs-link-bg;
                    &:not(.disabled) {
                        color: $body-color;
                    }
                    &.active {
                        background-color: $nav-tabs-link-active-bg;
                        color: $primary;
                        border-right-color: transparent;
                    }
                }
                &:first-child {
                    .nav-link {
                        border-radius: $border-radius 0 0 0;
                    }
                }
                &:last-child {
                    .nav-link {
                        border-radius: 0 0 0 $border-radius;
                    }
                }
            }
        }
        &.nav-tabs-line {
            .nav-link {
                border: 0;
                background-color: transparent;
                &.active {
                    border-bottom: 2px solid $primary;
                }
            }
        }
    }
}
.tab-content {
    &.tab-content-vertical {
        height: 100%;
    }
}
