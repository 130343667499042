// Demo Styles

.main-content {
    color: $body-color;
    font-size: 16px;
    > .page-title {
        margin-bottom: 1rem;
        font-weight: 400;
    }
    > h4 {
        margin-top: 1.5rem;
        margin-bottom: 0.875rem;
        &::before {
            display: block;
            height: 5.4rem;
            margin-top: -6rem;
            content: '';
        }
    }
    > hr {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .example {
        font-size: 0.875rem;
        letter-spacing: normal;
        padding: 10px;
        background-color: $card-bg;
        border: 4px solid $border-color;
        position: relative;
        @media (min-width: 576px) {
            padding: 25px;
        }
    }
    .highlight {
        position: relative;
        background-color: $card-bg;
        padding: 15px;
        margin: 0 0 1rem;
        .nav-tabs {
            /*rtl:ignore*/
            direction: ltr;
            padding-inline-start: 0;
            padding-inline-end: 40px;
            .nav-item {
                .nav-link {
                    background-color: $card-bg;
                    font-size: 0.85rem;
                    border: 0;
                    color: $text-muted;
                    padding: 0.5rem 2.5rem;
                    &.active {
                        border-bottom: 2px solid $primary;
                        color: $body-color;
                    }
                }
                &:last-child {
                    .nav-link {
                        border-right: 0;
                    }
                }
            }
        }
        pre {
            padding: 15px;
            font-size: 0.875rem;
            font-family: $font-family-sans-serif;
            background: transparent;
            line-height: 1.4;
            margin: 0;
            code {
                font-family: $font-family-sans-serif;
                padding: 0;
                tab-size: 6;
                color: $body-color;
                background: $card-bg;
                text-shadow: none;
                line-height: 1.35rem;
                .token {
                    &.url,
                    &.string,
                    &.entity,
                    &.operator {
                        background: none;
                    }
                }
            }
        }
        .btn-clipboard {
            position: absolute;
            top: 60px;
            /*rtl:ignore*/
            right: 16px;
            font-size: 12px;
            padding: 1px 6px;
            background: rgba($primary, 0.1);
            &:hover {
                background: rgba($primary, 0.2);
                transition: background 0.3s ease-in-out;
            }
        }
    }

    // RTL fix
    > p,
    > h1,
    > h2,
    > h3,
    > h4,
    > h5,
    > h6 {
        /*rtl:raw:	
      direction: ltr;	
    */
    }
}

.example {
    .btn-toolbar {
        + .btn-toolbar {
            margin-top: 0.5rem;
        }
    }
    .modal {
        &.static {
            position: static;
            display: block;
        }
    }
    .navbar {
        position: relative;
        padding: 0.5rem 1rem;
        left: auto;
        width: 100%;
        height: auto;
        z-index: 9;
        border-bottom: 0;
        box-shadow: none;
        .navbar-brand {
            font-size: 1.25rem;
        }
    }
    .progress {
        + .progress {
            margin-top: 10px;
        }
    }
    .perfect-scrollbar-example {
        position: relative;
        max-height: 250px;
        background: $card-bg;
    }
    .scrollspy-example {
        position: relative;
        height: 200px;
        margin-top: 0.5rem;
        overflow: auto;
    }
    .scrollspy-example-2 {
        position: relative;
        height: 350px;
        overflow: auto;
    }
    nav {
        .breadcrumb {
            margin-bottom: 0.75rem;
        }

        &:last-child {
            .breadcrumb {
                margin-bottom: 0;
            }
        }
    }
}

.page-breadcrumb {
    margin-bottom: 15px;
    .breadcrumb {
        padding: 0;
        background: $body-bg;
    }
}

.nobleui-logo {
    font-weight: 700;
    font-size: 25px;
    color: darken($primary, 50%);
    span {
        color: $primary;
        font-weight: 300;
    }
    &:hover {
        color: darken($primary, 50%);
    }
    &.logo-light {
        color: $body-color;
    }
}
